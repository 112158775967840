import React from 'react';
import { Link } from 'gatsby';
import { routes } from 'routes';
import { SEO } from 'components/SEO';
import emojiImg from './images/emoji.png';
import './styles.scss';

const NotFoundPage = () => (
  <main className="page notfound-page container">
    <SEO title="404" />
    <section>
      <h1>
        4<img src={emojiImg} alt="Грустный смайлик" />4
      </h1>
      <h2>Упс! Страница не найдена</h2>
      <p>
        К сожалению такой страницы не существует, она была удалена или
        переехала.
      </p>
      <Link to={routes.home.path}>Вернуться на главную страницу</Link>
    </section>
  </main>
);

export default NotFoundPage;
